.logoReubicacion {
  padding-left: calc((100vw - 1402px) / 2)!important;
}

#cajaAmenities {
  background-color: white;
  width: 29%;
  height: 45px;
  position: absolute;
  z-index: 1;
}
.btnn-tabla {
  padding: 3px 14px 3px 14px;
}

.hrMobiTop{
  display: none;
}

.tituloContactoSection{
  margin-bottom: 5rem!important;
}

#contacto {
  padding-top: 0px!important;
}

.form-control {
  color: black!important;
}

#cajaEdiffmapper {
  background-color: #ececec;
  width: 29%;
  height: 45px;
  position: absolute;
  z-index: 1;
  margin-top: -44px;
}

#cajaTable {
  background-color: #fff;
  width: 29%;
  height: 45px;
  /* position: absolute; */
  float: right;
  z-index: 1;
  margin-top: 101px;
}

/* .slide {
  padding: 35px!important;
}
 */
.borderFooter {
  border-right: 1px solid rgb(151 151 151 / 1);
  height: 48pt;
  justify-content: center;
  display: flex;
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:focus {
  color: black!important;

  text-decoration: underline;
}
.altoImgCentro {
  height: 600px;
}
.container1298px {
  max-width: 1298px;
}

.altoForm {
  height: 50px!important;
}
.whatsappFooter {
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 99999999;
}
.footLegal {
  text-align: left;
  padding-right: 18px;
}
.whatsappimgFooter {
  width: 4vw;
}
/* .carousel .slide {
  background: none!important;
} */

.contactooo {
  font-size: 20px;
  text-decoration: underline!important;
  color: black;
  font-family: GT-America-Regular;
}
.sLinea {
	text-decoration: unset!important;
  }
.btnCollapseTabla {
  text-align: right;
  cursor: pointer;
  padding-right: 10px;
}

.lineaTabla {
  display: grid;
  grid-template-columns: 83% 7% 10%;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  border-top: 1pt solid black;
  padding-top: 13px;
  padding-bottom: 13px;

  font-size: 20px;
}

a:hover {
  text-decoration: underline;
  color: #7fe0a7!important;
}

.buttonBookTable {
  padding: 2px 19px;
}

.boxUnidades .scrollBarLotes:first-child div {
  border-top: 0pt solid #000;
}

.scrollBarLotes::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #000; /* or add it to the track */
}

/* Add a thumb */
.scrollBarLotes::-webkit-scrollbar-thumb {
  background: #7fe0a7;
}

.scrollBarLotes {
  height: 186px;
  overflow: scroll;
  overflow-x: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
}

.lineaTablaLotesRow {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 11px 0px;
  gap: 10px 0px;
  border-top: 0pt solid black;
  padding-top: 2px;
  padding-bottom: 12px;
  font-size: 20px;
}

.lineaTablaLotes {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  grid-template-rows: 1fr;
  gap: 10px 0px;
  border-top: 1pt solid black;
  padding-top: 13px;
  padding-bottom: 13px;

  font-size: 20px;
}

.ubicacionRow {
  margin: 0px;
  justify-content: center;
  margin-top: 50px;
}

.lastUnidad {
  border-bottom: 1pt solid black;
}

.tablaUnidadesCol1 {
  font-family: GT-America-Black;
  font-weight: bold;
  font-size: 20px;
}

.rowUnidades .col-lg-6 {
  padding-right: 0px!important;
}

.btn-tabla {
  padding: 3px 16px;
}

.sectionFooter {
  padding-top: 82px!important;
  padding-bottom: 82px!important;
  position: relative;
  background-color: #fff;
}

.tituloSeccion {
  margin-bottom: 21px;
  font-family: "GT-America-Black";
  text-align: center;
  font-size: 35px;
  width: 100%;

  font-weight: bold;
}

#mappGoogle {
  filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="g"><feColorMatrix type="matrix" values="0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0.3 0.3 0.3 0 0 0 0 0 1 0"/></filter></svg>#g');
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayScale=1);
}

.mappa {
  margin-top: 146px;
}

.btnUnidades {
  margin-right: 34pt;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%)!important;
}

.btnUnidadesActive {
  font-family: "GT-America-Black";
  background-color: #80e0a7!important;
  border: 1px solid #80e0a7!important;
  padding: 6px 25px;
  border-radius: 0px;
}

.btncorto {
  font-family: "GT-America-Black"!important;
  padding: 6px 25px;
  border-radius: 0px;
}

.rowUnidades {
  height: 456px;
}

.descSeccion {
  font-family: "GT-America-Regular";
  text-align: center;
  font-size: 20px;
  line-height: 28px;
}

a:hover {
  text-decoration: underline;
  color: white;
}

.subrayado {
  text-decoration: underline!important;
}
.despegado {
  padding-top: 18px;
  padding-left: 36px;
}

.despegadoFIX {
  padding-left: 36px;
}

.alturaMenu {
  height: 127px;
}

.squareBottom {
  width: 200px;
  height: 47px;
  background-color: white;
  position: absolute;
  bottom: 0;
}

.popupinteractivo {
  position: absolute;
  top: 0px;
  width: 200px;
  background-color: red;
  z-index: 999999999999999999999999;
}

.darkheader #direccion {
  padding-top: 35px!important;
}

.darkheader {
  height: 127px;
}

.darkheader #logotipo {
  filter: invert(1);
  width: 161px;
}

.footerDesc {
  color: white;
  font-size: 15px;
  margin: 0;
  line-height: 21px;
  font-family: "GT-America-Regular";
}

.faidenAdamo {
  color: white;
  font-size: 21px;
  margin: 0;
  margin-top: 15px;
  line-height: 22px;
  font-family: "GT-America-Regular";
  text-align: center!important;
}

.empresas-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 10px;
  justify-items: center;
}

.darkheader #navegacionDirecc {
  display: none;
}

.empresaLogo {
  text-align: center;
  margin-top: 15px;
  width: 100%;
}

.bordeEmpresaDerecha {
  border-right: 1px solid rgb(151, 151, 151);
}

.darkheader #btnContacto {
  /*margin-top: 14px!important;
	padding: 6px 21px!important;
*/
  margin-top: 20px;
  padding: 5px 22px;
}

.bg-darkkk {
  background-color: #000000!important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000000;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

span.title-border,
.features-desc a:hover,
.service-box .service-icon,
.company-sub-menu li a:hover,
.text-custom,
#topnav .has-submenu.active .submenu li.active > a,
#navigation li.active a,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu .has-submenu .submenu > li:hover > a,
#topnav .navigation-menu > li:hover > a {
  /*color: rgb(128,224,167)!important;*/
  text-decoration: underline;
}

.btn-custom,
.bg-custom,
.play-icon-circle,
.main-slider .flex-control-paging li a.flex-active,
.features-border,
.team-social li a:hover,
.plan-price h6,
.footer-icons li a:hover,
#topnav .navbar-toggle.open span:hover {
  background-color: rgb(128, 224, 167);
}

.tabsClickeables {
  display: grid;
  margin-bottom: 27pt;
  grid-template-columns: 20% 30% 30% 20%;
  grid-template-rows: 1fr;
  grid-gap: 11px 0px;
  grid-gap: 10px 0px;
  gap: 10px 0px;
  border-top: 0pt solid black;
  padding-top: 2px;
  padding-bottom: 12px;
  font-size: 20px;
}

.tituloUnidadesMobile {
  font-family: "GT-America-Black";
  font-size: 30px;
  font-weight: 900;
  line-height: 75px;

  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.tabsClickeablesunidades {
  display: grid;
  margin-bottom: 27pt;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 11px 0px;
  grid-gap: 10px 0px;
  gap: 10px 0px;
  border-top: 0pt solid black;
  padding-top: 2px;
  padding-bottom: 12px;
  font-size: 20px;
}

.clickeableTab {
  cursor: pointer;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  border-bottom: 1px solid black;
  height: 32px;
  margin-bottom: -15px;
  font-family: "GT-America-Black";
}

.activeTab {
  /*color:  rgb(128,224,167)!important;*/
  border-bottom: 6px solid rgb(128, 224, 167);
}

.containerMApper {
  position: relative;
}

.burgerMenu_coll {
  z-index: 9999999999;
}
.tooltipMapper {
  position: absolute;
  color: #fff;
  background: none;
  transform: translate3d(-160%, -60%, 0);
  border-radius: 0px;
  pointer-events: none;
  z-index: 1000;
  width: 220px;
}

.tooltipMapperSide {
  position: absolute;
  color: #fff;
  background: none;
  transform: translate3d(30%, -60%, 0);
  border-radius: 0px;
  pointer-events: none;
  z-index: 1000;
  width: 220px;
}

.imgMapper {
  width: 100%;
}

.tituloMapper {
  font-weight: bold;
  font-size: 20px;
  color: #000;
  padding-left: 20px;
  padding-top: 20px;
  font-family: "GT-America-Black";
}

.descMapper {
  font-weight: 200;
  font-size: 18px;
  color: #000;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;
  font-family: "GT-America-Regular";
}
.unidadesBottom {
  margin-bottom: 50pt;
}
.btn-custom {
  border: 1px solid rgb(128, 224, 167)!important;
  border-radius: 0px;
  color: black!important;
}

.btn-custom-black-inverted {
  border: 1px solid #000000;
  background-color: white;
  border-radius: 0px;
  color: black;
  font-family: "GT-America-Regular";
  font-weight: 200;
}

.btn-custom-black-inverted:hover {
  background-color: black!important;
  border-color: black!important;
  color: white!important;
  font-weight: 200;
}

.btn-custom-black {
  border: 1px solid #000000!important;
  background-color: black;
  border-radius: 0px;
  color: white;
  font-weight: 200;
}

.team-box.active,
.team-social li a:hover,
.form-control:focus,
.footer-icons li a:hover,
#topnav .has-submenu.active .menu-arrow {
  border-color: rgb(128, 224, 167)!important;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:hover,
.open > .dropdown-toggle.btn-custom {
  background-color: black!important;
  border-color: black!important;
  color: white!important;
}

@media (min-width: 992px) {
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu .has-submenu .submenu > li:hover > a,
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li:hover > .menu-arrow {
    color: rgb(128, 224, 167);
  }
}

@media (max-width: 991px) {
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a,
  #topnav .menu-extras .menu-item .cart > a:hover,
  #topnav .menu-extras .menu-item .search > a:hover,
  #topnav .navigation-menu .submenu.open .has-submenu.open > a,
  #topnav .has-submenu.active a {
    color: rgb(128, 224, 167)!important;
  }
}

.alMedio {
  text-align: center;
  position: absolute;
  top: 50%;
  padding-right: 35px;
  padding-left: 35px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.titleUnidades {
  font-size: 40px;
  font-family: "GT-America-Black";
  font-weight: bold;
}

.descUnidades {
  font-size: 20px;
  font-family: "GT-America-Regular";
  margin-bottom: 21px;
}

.containerFull {
  max-width: 100%;
}

.posMargen {
  margin-left: 4vw;
}

.hideDesktop {
  display: none;
}

.fondo {
  background-color: none;
}

.btn-custom {
  font-weight: 300;
  font-size: 18px;
  font-family: "GT-America-Regular";
}

.footerDescCarrousel {
  margin-top: 31px;
  line-height: 28px;
  font-size: 20px;
  font-family: "GT-America-Regular";
  font-weight: 300;
}

.iconoContacto {
  margin-top: 22px;
  margin-right: 18px;
}

.containerFullUnidades {
  width: 100%;
  padding-right: 0px!important;
  padding-left: 0px!important;
  max-width: 100%;
}

.hrMobi {
  display: none;
}

/* celular */

@media only screen and (max-width: 768px) {
  .paddsCarrousel div div div ul .slide {
    /* padding-left: 3px!important; */
    /* padding-right: 3px!important; */
  }

  .carousel-root {
    outline: none;
  }

  .paddsCarrousel div {
    min-height: auto!important;
  }

  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
  .containerFull {
    max-width: none;
    padding-right: 34px!important;
  }
  .containerFullUnidades {
    max-width: none;
    padding-right: 0px!important;
    padding-left: 0px!important;
  }
  .clickeableTab {
    cursor: pointer;
    font-weight: 900;
    font-size: 35px;
    font-family: "GT-America-Black";
    float: left;
    margin-bottom: -4px;
    margin-right: 12px;
  }
  .hideMobile {
    display: none;
  }
  .showMobile {
    display: block;
  }
  .posMargen {
    margin-left: 0px;
  }
  .paddMargen {
    padding-left: 16x;
  }
  .btn-custom {
    border: 1px solid rgb(128, 224, 167)!important;
    border-radius: 0px;
    color: black!important;
    font-weight: 200;
    font-size: 14px;
    font-family: "GT-America-Regular";
  }
  .fondo {
    background-color: white;
  }
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100%;
    padding-right: 34px;
    padding-left: 34px;
    margin-right: auto;
    margin-left: auto;
  }
  .separacion {
    margin-bottom: 26pt;
  }
  .section {
    padding-top: 74px;
    padding-bottom: 77px;
    position: relative;
    background-color: #fff;
  }
  .footerDescCarrousel {
    margin-top: 31px;
    font-size: 15px;
    line-height: 28px;
    font-family: "GT-America-Regular";
    font-weight: 300;
  }
  .footerRow .col-12 {
    margin-bottom: 45px;
  }
  .despegado {
    padding-top: 0px;
  }
  .alturaMenu {
    height: 0px!important;
    flex-wrap: inherit;
  }
  .alMedio {
    position: initial;
    top: auto;
    transform: none;
  }
  .img1 {
    width: 90%;
  }
  .img2 {
    width: 90%;
  }
  .img3 {
    width: 90%;
  }
  .rowUnidades {
    height: auto;
    margin-right: 0px!important;
    margin-left: 0px!important;
  }
  .bg-afert_ubicacion {
    background-image: url(/static/media/antes_ubicacion.2dbd5997.jpg);
    background-size: cover;
    background-position: center center;
    height: 288px;
  }
  .descSeccion {
    font-family: "GT-America-Regular";
    text-align: center;
    font-size: 15px;
    line-height: 22px;
  }
  .footerRedes {
    padding: 0px;
  }
  .empresas-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px 10px;
    gap: 29px 10px;
    justify-items: left;
  }
  .sectionFooter {
    padding-top: 60px!important;
    padding-bottom: 0px!important;
    position: relative;
    background-color: #fff;
  }

.hrMobiTop{
	margin-top: 0rem;
    margin-bottom: 3rem;
	border: 0.1px solid rgb(151 151 151 / 35%);
    width: 90%;
    display: block;
}

  .hrMobi {
    margin-top: 5rem;
    margin-bottom: 1rem;
    border: 0.1px solid rgb(151 151 151 / 35%);
    width: 90%;
    display: block;
  }
  .hrMobi2 {
    margin-top: 4rem;
    margin-bottom: 0rem;
    border: 0.1px solid rgb(151 151 151 / 35%);
    width: 100%;
    display: block;
  }
  .darkheader {
    height: 95px;
  }

  /*mapper tooltip*/
  .containerMApper {
    position: relative;
  }

  .tooltipMapper {
    position: absolute;
    color: #fff;
    background: white;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 0px;
    pointer-events: none;
    z-index: 1000;
    width: 220px;
  }

  .imgMapper {
    width: 100%;
  }

  .tituloMapper {
    font-weight: bold;
    font-size: 20px;
    color: #000;
    padding-left: 20px;
    padding-top: 20px;
    font-family: "GT-America-Black";
  }

  .descMapper {
    font-weight: 200;
    font-size: 18px;
    color: #000;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 25px;
    font-family: "GT-America-Regular";
  }

  .whatsappimgFooter {
    width: 17vw;
  }

/*   .carousel .slide {
    background: none!important;
  } */

  .squareBottom22 {
    right: 0%!important;
  }

  /* style slider */
/*   .slider {
    margin-left: -41px!important;
  }
 */
/*   .container_footer_carr .carousel-root .carousel .slider-wrapper .slider {
    margin-left: 0px!important;
  } */

  .selected {
	  margin-right: 2%!important;
    margin-left: 2%!important;
  }

  .ubicacionRow {
    margin: 0px;
    justify-content: center;
    margin-top: 0px;
  }

  .btnUnidades {
    margin-right: 0pt;
  }

  .altoImgCentro {
    height: 180px!important;
  }

  .footLegal {
    text-align: center;
    padding-right: 0px;
  }

  .LineaAbierta {
    border-top: 0pt solid black!important;
    border-bottom: 0pt solid black!important;
  }

  .lineaTabla {
    display: grid;
    grid-template-columns: 46% 42% 11%;
    grid-template-rows: 1fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    border-top: 0pt solid black;
    border-bottom: 1pt solid black;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 20px;
  }

  .carroNoPadds {
    padding: 0px;
  }

  .borderFooter {
    border-right: 0px solid rgb(151 151 151 / 1);
    height: 9pt;
    justify-content: left;
    display: flex;
  }

  .lineaTablaLotes {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 2fr;
    gap: 10px 0px;
    border-top: 1pt solid black;
    padding-top: 13px;
    padding-bottom: 13px;

    font-size: 20px;
  }

  .contactateSpace {
    margin-top: -50px!important;
  }

  .lineaTablaLotesRow {
    display: grid;
    grid-template-columns: 18% 42% 40%;
    grid-template-rows: 1fr;
    grid-gap: 11px 0px;
    gap: 10px 0px;
    border-top: 0pt solid black;
    padding-top: 2px;
    padding-bottom: 12px;
    font-size: 20px;
  }

  .faidenAdamo {
    color: white;
    font-size: 21px;
    margin: 0;
    margin-top: 15px;
    line-height: 22px;
    font-family: "GT-America-Regular";
    text-align: center!important;
  }

  .sliderFooooot {
    height: 190px!important;
  }

  #cajaAmenities {
    display: none!important;
  }

  #cajaEdiffmapper {
    display: none!important;
  }

  #cajaTable {
    display: none!important;
  }

  .mappa {
    margin-top: 20px;
  }
  .unidadesBottom {
    margin-bottom: 0pt;
  }

  .darkheader #logotipo {
    filter: invert(1);
    width: 122px;
  }

  .mobileNavPos {
    position: absolute!important;
    top: 95px!important;
    left: 0px!important;
  }

  .btn-table-unidades {
    padding: 3px 16px 3px 16px;
    font-size: 18px;
    text-decoration: none!important;
  }

  .btn-table-unidades-inverse {
    padding: 3px 16px 3px 16px;
    font-size: 18px;
    text-decoration: none!important;
  }

  .sectionFooter {
    text-align: center;
  }

/*   .slide {
    padding: 0px!important;
  } */

  .ocultarenmobile {
    display: none!important;
  }



  .open .lines {
	right: calc(-14vw - 66px)!important;
  }

  .tituloContactoSection{
    margin-bottom: 7rem!important;
  }

  .carousel-root{
    margin-left: -0.75rem!important;
  }

}
